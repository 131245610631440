import React from 'react';
import { connect } from 'react-redux';
import { IError } from 'types/Error';
import { addError, addErrorWithMsgError } from 'state/errorHandler/errorActions';
import uiStrings from 'localization/uiStrings';
import { getTdcHostUrl, tdcHosts } from 'config/serviceConfig';
import { testLog } from 'businessLogic/pageHelper';

interface IErrorBoundaryProps {
    children: any;
    componentName: string;
    hasError?: () => any;
    suppressErrorMsg?: boolean;
}

// Since ErrorBoundary is already at a top level in a component, it is easy to add accessibility landmarks here.
// Following is in priority order:
interface ILandmarkProps {
    headerLandmark?: boolean;
    navLandmark?: boolean;
    mainLandmark?: boolean;
    asideLandmark?: boolean;
    sectionLandmark?: boolean;
    footerLandmark?: boolean;
}

interface IErrorBoundaryState {
    error?: IError;
}

interface IDispatchProps {
    addError?: (data: IError, type: string) => any;
    addErrorWithMsgError?: (data: IError, type: string, message: string) => any;
}

interface IFullErrorBoundaryProps extends IErrorBoundaryProps, ILandmarkProps, IDispatchProps { }

// A standard Error Boundary class as recommended by reactjs.org modified for redux
class ErrorBoundary extends React.PureComponent<IFullErrorBoundaryProps, IErrorBoundaryState> {

    public componentDidCatch = (error: any, info: any) => {
        const newError: IError = {
            message: error,
            stack: info,
        };

        const host = getTdcHostUrl() || '';
        testLog('TDC Error Boundary UX Alert: ' + JSON.stringify(newError));

        if (this.props.hasError) {
            this.props.hasError();
        }

        this.setState({
            error: newError,
        });

        if (this.props.addErrorWithMsgError && !this.props.suppressErrorMsg) {
            this.props.addErrorWithMsgError(
                newError,
                this.props.componentName,
                uiStrings.errorBoundary.startMsg + this.props.componentName + '. ' +
                newError.message + ' ' + uiStrings.errorBoundary.endMsg);
        } else if (this.props.addError && this.props.suppressErrorMsg) {
            this.props.addError(newError, this.props.componentName);
        }
    }

    public render() {
        if (this.state?.error) {
            return (null);
        } else {
            testLog('Rendering: ' + this.props.componentName)

            if (this.props.headerLandmark) {
                return <header>{this.props.children}</header>;
            } else if (this.props.navLandmark) {
                return <nav>{this.props.children}</nav>;
            } else if (this.props.mainLandmark) {
                return <main>{this.props.children}</main>;
            } else if (this.props.asideLandmark) {
                return <aside>{this.props.children}</aside>;
            } else if (this.props.sectionLandmark) {
                return <section>{this.props.children}</section>;
            } else if (this.props.footerLandmark) {
                return <footer>{this.props.children}</footer>;
            } else {
                return this.props.children;
            }
        }
    }
}

const mapDispatchToProps: IDispatchProps = {
    addError,
    addErrorWithMsgError,
};

export default connect<IDispatchProps>(null, mapDispatchToProps)(ErrorBoundary);
