import React, { useState } from 'react';
import {
  ChoiceGroup,
  IChoiceGroupOption,
  mergeStyleSets,
  Stack
} from '@fluentui/react';

import {
  IImageCustomizerMap,
  IImageDefinitionMap,
  IImageDefinitionV2,
  IImageReplicationRegionsMap,
  IImageVersion,
  IImageVersionMap
} from 'types/ImageDefinitionV2';
import { IMessage } from 'types/message';
import { IBaseImage } from 'types/ImageDefinition';
import { IItemBase } from 'types/BaseTypes';
import { IHttpResponse } from 'types/HttpResponse';
import ErrorBoundary from 'components/errorHandling/errorBoundary';
import { commonStackTokens20 } from 'lib/jsxElementHelper';
import uiStrings from 'localization/uiStrings';
import { CustomerImageContainer } from './customerImageContainer';


const choiceGroupStyles = {
  label: {
    display: "inline"
  },
  flexContainer: {
    columnGap: "1em",
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "wrap"
  }
};

const styles = mergeStyleSets({
  choiceOption: {
    fontSize: 14,
    marginLeft: 20
  }
});

interface IImageContainerDispatchProps {
  addMessage: (message: IMessage) => any;
  createCustomerImageDefinition: (programId: string, definition: IImageDefinitionV2) => any;
  createCustomerImageVersion: (programId: string, definitionId: string, version: IImageVersion) => any;
  createImageDefinitionVersion: (programId: string, definitionId: string, version: IImageVersion) => any;
  createOrUpdateImageDefinition: (programId: string, definition: IImageDefinitionV2) => any;
  deleteImageDefinition: (programId: string, definitionId: string) => any;
  deleteImageDefinitionVersion: (programId: string, definitionId: string, versionId: string) => any;
  fetchImageCustomizers: (programId: string, definitionId: string, gitBranch?: string) => any;
  fetchImageDefinitions: (programId: string) => any;
  fetchImageReplicationRegions: (programId: string, definitionId: string) => any;
  fetchImageVersions: (programId: string, definitionId: string) => any;
  fetchImageVersionsLogs: (programId: string, definitionId: string, versionsId: string) => any;
  setImageDefinitionVersionToRelease: (programId: string, imageDefinitionId: string, versionId: string) => any;
}

interface IImageDefinitionContainerProps {
  baseImages: IBaseImage[];
  programId: string;
  imageCustomizerMap: IImageCustomizerMap;
  imageDefinitionMap: IImageDefinitionMap;
  imageReplicationRegions: IImageReplicationRegionsMap;
  imageVersionMap: IImageVersionMap;
  imageVersionLogs: IItemBase;
  updateImageVersionStatus: IHttpResponse;
}

interface IImageDefinitionsParentProps extends IImageContainerDispatchProps, IImageDefinitionContainerProps { }

export function ImageDefinitionsParentView(props: IImageDefinitionsParentProps) {

  const renderImagesContainer = () => {
    return (
      <CustomerImageContainer
        addMessage={props.addMessage}
        baseImages={props.baseImages}
        deleteImageDefinition={props.deleteImageDefinition}
        deleteImageDefinitionVersion={props.deleteImageDefinitionVersion}
        createCustomerImageDefinition={props.createCustomerImageDefinition}
        createCustomerImageVersion={props.createCustomerImageVersion}
        createOrUpdateImageDefinition={props.createOrUpdateImageDefinition}
        fetchImageDefinitions={props.fetchImageDefinitions}
        fetchImageVersions={props.fetchImageVersions}
        imageCustomizerMap={props.imageCustomizerMap}
        imageDefinitionMap={props.imageDefinitionMap}
        imageReplicationRegions={props.imageReplicationRegions}
        imageVersionMap={props.imageVersionMap}
        imageVersionLogs={props.imageVersionLogs}
        programId={props.programId}
        updateImageVersionStatus={props.updateImageVersionStatus}
      />
    );
  }

  return (
    <ErrorBoundary componentName="ImageDefinitionsParentView">
      <Stack tokens={commonStackTokens20}>
        {renderImagesContainer()}
      </Stack>
    </ErrorBoundary>
  )
}